/* .insta_mob {
  max-width: 25%;
} */
.insta_div_new {
  padding: 4% 0%;
}
.Insta_letter {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 42px;
  text-align: center;

  color: #3c3c3c;
}

@media screen and (min-width: 425px) and (max-width: 600px) {
  .insta_img {
    width: 119px;
    height: 119px;
  }
  .insta_mob {
    width: 119px;
    height: 119px;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .insta_img {
    width: 110px;
    height: 110px;
  }
  .insta_mob {
    width: 110px;
    height: 110px;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .Insta_imgs_div {
    gap: 5px !important;
  }
  .Insta_imgs_mob {
    gap: 5px !important;
  }
  .insta_img {
    width: 100px;
    height: 100px;
  }
  .insta_mob {
    width: 100px;
    height: 100px;
  }
}

@media screen and (min-width: 425px) and (max-width: 600px) {
  .Insta_letter {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 33px;
  }
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .Insta_letter {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
  }
}

.Insta_imgs_div {
  margin: auto;
  justify-content: space-between;
}

@media screen and (min-width: 1125px) {
  .Insta_imgs_div {
    width: 1110px;
  }
  .insta_img {
    width: 350px;
    height: 350px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1124px) {
  .Insta_imgs_div {
    width: 980px;
  }
  .insta_img {
    width: 30%;
    height: 350px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .Insta_imgs_div {
    width: 750px;
  }
  .insta_img {
    width: 30%;
    height: 350px;
  }
}

@media screen and (min-width: 601px) and (max-width: 767px) {
  .Insta_imgs_div {
    width: 560px;
  }
  .insta_img {
    width: 30%;
    height: 250px;
  }
}

@media screen and (max-width: 600px) {
  .Insta_imgs_div {
    justify-content: center;
    gap: 0.5rem;
    margin-bottom: 15px;
  }
  .insta_img {
    /* max-width: 25%; */
  }
}
