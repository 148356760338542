.shipping_heading_servives {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #3c3c3c;
}
.yellowText1 {
  color: transparent;
}
.faq_page {
  width: 100%;
  margin: 0px auto;
  padding: 4% 6%;
  max-width: 1450px;
}
.shipping_faq_head_para_otherpage {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  padding: 10px 0px;
  color: #616161;
}
.question_title1_otherpages {
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: #3c3c3c;
}
.question_title_otherpages {
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: #3c3c3c;
}
.faq_answer_other {
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #616161;
  background-color: white;
  padding: 0% 1% 1% 1%;
}
.faqs_padding {
  padding-top: 3%;
  padding-bottom: 3%;
}
.shipping_heading2 {
  display: none;
}

.shipping_heading {
  display: none;
}
.icon_faq1 {
  font-size: 20px;
  color: #fddb31;
}
.faq_main_otherpages {
  display: flex;
  flex-direction: column;
  margin: 0px auto;
  max-width: 1280px;
  width: 100%;
  margin: 0px auto;
  padding: 2% 4%;
  background: #f8f9fa;
}
.faq_main1_otherpages {
  display: flex;
  flex-direction: column;
  margin: 0px auto;
  max-width: 1280px;
  width: 100%;
  padding: 0% 4%;
  background: #f8f9fa;
}
.otherpage_faq {
  background: #f8f9fa;
  padding: 1% 0%;
  margin-top: -12px;
}
.shipping_heading1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-transform: capitalize;

  color: #3c3c3c;

  width: 100%;
  text-align: left;
}
.answer_div {
  width: 100%;

  font-size: 20px;
}

.accordian_body1 {
  width: 100%;
}

.shipping_faq_head_para {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #6b6b6b;
  width: 100%;
}
.shipping_faq_head_line {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  padding: 10px 0px;
  color: #616161;
}

.faq_main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 4%;
}

.fag_question {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  -webkit-justify-content: space-between !important;
  background-color: #ffffff;
  padding: 10px 1% 0px 1%;
  width: 100%;
}
.fag_question1 {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  -webkit-justify-content: space-between !important;
  border-bottom: 1px solid #d9d9d9;
  padding: 5px 10px 5px 0px;
  width: 100%;
}

.accordian_body {
  width: 80%;
}

.plus_div {
  width: 100%;
  margin-bottom: 10px;
}

.faq_btn {
  background: none;
  border-radius: 20px;
  border: none;
}
.faq_answer {
  background-color: #f8f9fa;
  padding: 30px 15px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #616161;
}

.question_title {
  padding-top: 3px;
  width: 75%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: #3c3c3c;
}

.question_title1 {
  font-size: 20px;
  padding-top: 3px;
  color: #555555;
  width: 75%;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: #3c3c3c;
}

.icon_faq {
  fill: #fddb31;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media only screen and (min-width: 600px) {
  .faq_page {
    padding: 2% 6%;
  }

  .yellowText1 {
    color: #fddb31;
  }
  .faq_main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding-top: 3%;
    background: white;
    text-align: center;
    width: 100%;
    margin: 0px auto;
  }

  .shipping_faq_head_para {
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    color: #3c3c3c;
    text-align: left;
    max-width: 1280px;
    width: 100%;
    padding: 0% 0%;
  }

  .shipping_heading {
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;
    color: #3c3c3c;
    max-width: 1280px;
    width: 100%;
    text-align: left;
    padding: 0% 0%;
    display: block;
  }
  .shipping_heading1 {
    display: none;
  }

  .faq_main1 {
    width: 100%;
  }

  .plus_div {
    max-width: 1500px;
    width: 100%;
    margin: 0px auto;
    margin-bottom: 10px;
  }

  .accordian_body1 {
    width: 99%;
    max-width: 1280px;
    margin: 0px auto;
    padding: 1% 0%;
  }

  .question_title {
    padding-top: 3px;
    color: #3c3c3c;
    width: 75%;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
  }

  .question_title1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    padding-top: 3px;

    color: #3c3c3c;
    width: 75%;
  }

  .faq_answer {
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    color: #616161;

    background: #f8f9fa !important;
  }
  .shipping_heading_servives {
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;
    color: #3c3c3c;
  }
  .shipping_faq_head_para_otherpage {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    padding: 10px 0px;
    color: #616161;
  }
}

.row_Accordiab > div {
  padding-right: 0px !important;
}
