.about_progress {
  background: #616161;
  padding: 3% 0%;
}

.profike_carousal_scroll > .carousel .carousel-control-prev {
  left: -85px;
}
.carousel-item {
}
.progress_main {
  margin-top: 30px !important;
  /* max-width: 1280px; */
  width: 100%;
  margin: 0px auto !important;
}
.progress_circle {
  width: 40%;
}
.profile_carousal_main {
  max-width: 1110px;
  width: 100%;
  margin: 0px auto;
}
.carousel-inner {
  width: 100%;
  margin: 0px auto;
}
.CircularProgressbar {
  width: 100% !important;
}
.heading_img {
  width: 100%;
  height: 250px;
  text-align: center;
}

.heading_img img {
  width: 90%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.progress_data {
  padding: 7% 7%;
}

.about_progress_butotn_div {
  padding: 5% 1%;
}
.about_progress_btn {
  padding: 15px 20px;
  gap: 10px;
  background: #fddb31;
  border-radius: 5px;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #3c3c3c;
  margin-right: 10px;
}
.about_progress_btn1 {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #fddb31;
  background: none;
  border: 1px solid #e2c11c;
  padding: 15px 10px;

  border-radius: 5px;
  outline: none;
}

.mission_div h5 {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #fddb31;
}

.mission_div h6 {
  max-width: 346px;
  width: 100%;
  font-weight: 700;
  font-size: 22px;
  line-height: 146.19%;
  color: #ffffff;
}

.mission_div p {
  font-weight: 400;
  font-size: 16px;
  line-height: 146.19%;
  color: #ffffff;
}

.circle_progress {
  display: flex;
  justify-content: start;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
}

.yellow_circle {
  display: flex;

  justify-content: start;
  align-items: center;
  margin: 10px 0px;
}

.yellow_circle h5 {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #fddb31;
  margin-top: 20px;
  text-align: left;
}

.yellow_circle p {
  font-weight: 400;
  font-size: 14px;
  line-height: 146.19%;
  margin-top: 10px;
  text-align: left;
  color: #ffffff;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #fddb31 !important;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}
.yellow_circle_text {
  margin-left: 8px;
  width: 60%;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #ffffff !important;
  font-size: 30px !important;
  dominant-baseline: middle;
  text-anchor: middle;
}

/* carousal css */
.profile_data_carousal {
  width: 100%;
  margin: 0px auto;

  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.person_pic {
  width: 100%;
  height: 100%;
  border-radius: 5px 5px 0px 0px;
}

.profile_data_carousal h6 {
  padding-top: 20px;
  padding-left: 20px;
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: #3c3c3c;
}

.profile_data_carousal p {
  padding-top: 10px;
  padding-left: 20px;
  padding-bottom: 10%;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #616161;
}

/* periorty css */

.periorty {
  width: 100%;
  padding: 7% 7%;
}

.periorty h6 {
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #3c3c3c;
}

.periorty p {
  max-width: 352px;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #616161;
}

.periorty_btn {
  padding: 10px 15px;
  background: #fddb31;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  border: none;
  outline: none;
  font-family: "Roboto";
  border-radius: 5px;
  color: #3c3c3c;
}
.periorty_btn:hover {
  background-color: #e2c11c;
}

@media screen and (min-width: 600px) {
  .about_progress {
    box-sizing: border-box;
  }
  .yellow_circle {
    margin: 0px 0px;
  }
  .yellow_circle_text {
    margin-left: 8px;
    width: 100%;
  }

  .progress_main {
    display: flex;
    margin: 0;
    flex-direction: column-reverse;
  }

  .heading_img {
    height: 350px;
  }

  .heading_img img {
    height: 100%;
    width: 90%;
    object-fit: cover;
    border-radius: 10px;
  }

  .wrapperDiv {
    box-sizing: border-box;
    flex: 1;
  }

  .progress_data {
    box-sizing: border-box !important;
    padding: 60px 8%;
  }

  .mission_div h5 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #fddb31;
  }

  .mission_div h6 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 146.19%;
    max-width: 100%;
    color: #ffffff;
  }

  .mission_div p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 146.19%;
    color: #ffffff;
  }

  .yellow_circle h5 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 146.19%;
    text-align: left;
    color: #fddb31;
  }

  .yellow_circle p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 146.19%;
    color: #ffffff;
    text-align: start;
  }

  .yellow_circle {
    align-items: center;
    flex-direction: row;
    max-width: 600px;
    align-items: center !important;
  }

  .yellow_circle:nth-of-type(2) {
    align-items: center;
  }

  .CircularProgressbar {
    width: 90% !important;
    margin-right: 20px;
    margin-top: 10px;
  }

  .circle_progress {
    justify-content: space-between;
  }

  .periorty {
    max-width: 1500px;
    width: 100%;
    margin: 0px auto;
    padding: 30px 6%;
  }

  .periorty h6 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 146.19%;
    color: #3c3c3c;
  }

  .periorty p {
    max-width: 100%;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 146.19%;
    color: #616161;
  }

  .carousal_profile_mob {
    display: none;
    margin: 30px 0px;
  }

  .profile_data_carousal {
    width: 100%;
    margin: 0px auto;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgb(0 0 0 / 10%);
    border-radius: 5px;
  }
}

@media screen and (min-width: 1015px) {
  .about_progress {
    box-sizing: border-box;
  }
  .yellow_circle h5 {
    margin-top: 24px;
  }
  .periorty {
    padding: 30px 5%;
  }
  .progress_circle {
    width: 38%;
  }
  .meet_up_carOUSl {
    padding: 0% 2%;
  }

  .profile_data_carousal {
    width: 100%;
    margin: 0px auto;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgb(0 0 0 / 10%);
    border-radius: 5px;
  }

  .progress_main {
    display: flex;
    margin: 0;
    flex-direction: row;
    padding: 0% 2%;
  }

  .heading_img {
    flex: 1;
    height: 100%;
  }

  .heading_img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .wrapperDiv {
    box-sizing: border-box;
    flex: 1;
  }

  .progress_data {
    box-sizing: border-box !important;
    padding: 0px 10px;
    height: 100%;
  }

  .mission_div h5 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 19px;
    color: #fddb31;
  }

  .mission_div h6 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 136.19%;
    width: 180%;
    color: #ffffff;
  }

  .mission_div p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 146.19%;
    width: 100%;
    color: #ffffff;
  }

  .yellow_circle h5 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 146.19%;
    text-align: left;
    color: #fddb31;
  }

  .yellow_circle p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 146.19%;
    width: 100%;
    color: #ffffff;
    text-align: start;
  }

  .yellow_circle {
    align-items: flex-start;
  }

  .yellow_circle:nth-of-type(2) {
    align-items: flex-start;
  }

  .CircularProgressbar {
    width: 50% !important;
    align-items: center;
    margin-right: 20px;
    margin-top: 10px;
  }
  .CircularProgressbar {
    vertical-align: middle;
    width: 90% !important;
  }

  .circle_progress {
    justify-content: space-between;
    margin-top: 30px;
  }

  .periorty h6 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 146.19%;
    color: #3c3c3c;
  }

  .periorty p {
    max-width: 100%;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 146.19%;
    color: #616161;
  }
  .about_progress_butotn_div {
    padding: 3% 1%;
  }
}

@media screen and (min-width: 1100px) {
  .heading_img img {
    height: 720px;
  }
  .periorty {
    padding: 3% 9%;
  }
  .carousel-item {
    padding: 0% 0%;
  }
}

@media screen and (min-width: 1125px) {
  .meet_surgeons_img {
    width: 350px;
    height: 320px;
    border-radius: 8px 8px 0px 0px;
  }
  .react-multi-carousel-track {
    gap: 30px !important;
  }
  .react-multi-carousel-list {
    width: 1110px !important;
    margin: 0% auto;
  }
  .react-multi-carousel-item {
    width: 350px !important;
  }
}

.react-multi-carousel-track {
  padding-left: 31% !important;
}

.react-multi-carousel-item {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(214, 214, 214, 0.25);
  border-radius: 8px;
}

.surgeons_name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #3c3c3c;
  margin-bottom: 0px;
  margin-top: 4px;
}

.surgeons_specialization {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #616161;
  margin-bottom: 0px;
}

.surgeons_info_div {
  padding-left: 25px;
}

@media screen and (min-width: 992px) and (max-width: 1124px) {
  .react-multi-carousel-track {
    gap: 10px !important;
  }
  .react-multi-carousel-list {
    width: 980px !important;
    margin: 0% auto;
  }
  .meet_surgeons_img {
    width: 320px;
    border-radius: 8px 8px 0px 0px;
  }
  .react-multi-carousel-item {
    width: 320px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .react-multi-carousel-track {
    gap: 5px !important;
  }
  .react-multi-carousel-list {
    width: 760px !important;
    margin: 0% auto;
  }
  .meet_surgeons_img {
    width: 250px;
    border-radius: 8px 8px 0px 0px;
  }
  .react-multi-carousel-item {
    width: 250px !important;
  }
  .surgeons_info_div {
    padding-left: 10px;
  }
}

.surgeons_carousel > .carousel .carousel-control-next {
  right: -60px;
  top: 40%;
  height: 40px;
  width: 40px;
  background: #ffffff;
  box-shadow: 4px 4px 15px rgba(56, 56, 56, 0.09);
  border-radius: 3px;
  opacity: 1 !important;
}

.surgeons_carousel > .carousel .carousel-control-prev {
  left: -60px;
  top: 40%;
  height: 40px;
  width: 40px;
  background: #ffffff;
  box-shadow: 4px 4px 15px rgba(56, 56, 56, 0.09);
  border-radius: 3px;
  opacity: 1 !important;
}

@media screen and (min-width: 375px) and (max-width: 767px) {
  .meet_surgeons_img {
    height: 223px;
    width: 250px;
    border-radius: 8px 8px 0px 0px;
  }
  .surgeons_info_div {
    padding-left: 10px;
  }
}

.surgeons_carousel > .carousel {
  width: 250px;
  margin: 0% auto;
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .meet_surgeons_img {
    height: 223px;
    width: 230px;
    border-radius: 8px 8px 0px 0px;
  }
  .surgeons_info_div {
    padding-left: 10px;
  }
  .surgeons_carousel > .carousel {
    width: 230px;
    margin: 0% auto;
  }
  .surgeons_carousel > .carousel .carousel-control-next {
    right: -40px;
    width: 30px;
  }
  .surgeons_carousel > .carousel .carousel-control-prev {
    left: -40px;
    width: 30px;
  }
}

.testi_heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  color: #3c3c3c;
  margin-bottom: 30px;
  margin-top: 60px;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .testi_heading {
    font-family: "Roboto";
    font-weight: 600;
    font-size: 28px;
    line-height: 33px;
    margin-bottom: 25px;
    margin-top: 40px;
  }
  .surgeons_card {
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(214, 214, 214, 0.25);
    border-radius: 8px;
  }
  .surgeons_name {
    margin-top: 8px;
  }
  .surgeons_specialization {
    margin-bottom: 10px;
  }
}

.react-multiple-carousel__arrow {
  z-index: 10 !important;
}
