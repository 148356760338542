.test_bg {
  background-color: #e7e7e7;
  border-radius: 15px;
}

.test_text {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #616161;
  max-width: 115ch;
  padding: 25px 0px 10px;
  margin-bottom: 0px;
}

.py-5 {
  padding-top: 0rem !important;
  padding-bottom: 0px !important;
}

.testimonial_image_div {
  width: 60px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0px auto;
}

.testimonial_image {
  border-radius: 50%;
  max-width: 100%;
  max-height: 100%;
}
.testimonial_name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #616161;
  margin-bottom: 23px;
}
.testimonial_text {
  font-weight: 500;
  font-size: 11px;
  font-family: "Poppins", sans-serif;
  line-height: 15.83px;

  text-align: center;
}
/* .testi_heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 60px;
  text-transform: capitalize;
  color: #3a3a3a;
  line-height: 51.8px;
  color: var(--title-bg);
  text-transform: uppercase;
} */
.testimonial {
  background-color: var(--white);
  position: relative;
  padding: 45px 0px 50px;
  margin: 0px auto;
}
.carousel .carousel-indicators {
  bottom: -20% !important;
}
.carousel .carousel-indicators button {
  background-color: black !important;
  width: 4px !important;
  padding: 3px !important;
  border-radius: 50% !important;
}

.carousel-control-prev-icon {
  background-image: url("../Assets/images/services/testPrevIcon.png") !important;
  width: 8px !important;
  height: 12px !important;
  background-position: center;
  background-size: cover;
}
.carousel-control-next-icon {
  background-image: url("../Assets/images/services/testNextIcon.png") !important;
  width: 8px !important;
  height: 12px !important;
  background-position: center;
  background-size: cover;
}
.carousel-indicators {
  display: none !important;
}

.testimonial_para {
  width: 80%;
  margin: 15px auto;
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
}
.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 1 !important ;
}

.testimonial_para {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;

  width: 70%;
  color: #6b6b6b;
}
.testimonial {
}

/* .testi_heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 33px;
  text-transform: capitalize;
  color: #3c3c3c;
  margin-bottom: 25px;
  margin-top: 40px;
} */
.testimonial_name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #616161;
}
.testimonial_text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  color: #6b6b6b;
}
@media only screen and (min-width: 600px) {
  .testimonial_para {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    width: 70%;
    color: #6b6b6b;
  }
  .new {
    margin: 0px auto;
  }
  .py-5 {
    padding-top: 0rem !important;
    padding-bottom: 0px !important;
  }
  .testimonial {
    width: 100% !important;
    max-width: 1110px;
    padding: 50px 0px 50px;
  }
  /* .testi_heading {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    text-transform: capitalize;
    color: #3c3c3c;
    padding-bottom: 1%;
  } */
  .testimonial_para {
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #3c3c3c;
  }
  .testimonial_name {
    font-size: 24px;
    line-height: 28px;
    color: #616161;
  }
}

@media screen and (max-width: 600px) {
  .testimonial > .carousel > .carousel-control-next {
    width: 19% !important;
    top: 0px !important;
    right: -12% !important;
  }

  .testimonial > .carousel > .carousel-control-prev {
    width: 19% !important;
    top: 0px !important;
    left: -12% !important;
  }

  .testimonial
    > .carousel
    > .carousel-control-next
    .carousel-control-next-icon {
    width: 6px !important;
    height: 12px !important;
  }

  .testimonial
    > .carousel
    > .carousel-control-prev
    .carousel-control-prev-icon {
    width: 6px !important;
    height: 12px !important;
  }
  .testimonial_name {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }
}

.testimonial > .carousel {
  width: 90% !important;
  margin: 0 auto;
}

@media screen and (min-width: 1200px) {
  .testimonial > .carousel .carousel-control-prev {
    left: -106px;
  }
  .testimonial > .carousel .carousel-control-next {
    right: -106px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial > .carousel .carousel-control-prev {
    left: -60px;
  }
  .testimonial > .carousel .carousel-control-next {
    right: -60px;
    width: 10%;
  }
}

@media screen and (min-width: 601px) and (max-width: 991px) {
  .testimonial > .carousel .carousel-control-prev {
    left: -40px;
  }
  .testimonial > .carousel .carousel-control-next {
    right: -40px;
    width: 10%;
  }
}
