* {
  padding: 0px;
  margin: 0px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  /* mix-blend-mode: overlay; */
}
body {
  overflow-x: hidden;
  box-sizing: border-box !important;
}
.row {
  margin-top: 0px;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.row > * {
  padding-left: 0px !important;
}
#root {
  overflow-x: hidden !important;
}
ol,
ul {
  padding-left: 5% !important;
}
.errorMessage {
  display: none;
}
.navbar-expand-md .navbar-nav .nav-link {
  align-items: center;
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
  display: flex;
}
a {
  text-decoration: none !important;
  color: #6b6b6b !important;
}

a:hover {
  color: #dd0b09 !important;
}

.navbar-nav-scroll {
  max-height: 100vh !important ;
  overflow-y: scroll;
}
.setBgHomePage {
  background-image: url("../public/imgs/background_2x.webp");
  background-size: cover;
  background-position: 100% 100%;
  object-fit: contain;
  background-repeat: no-repeat;
  width: 100%;
  padding: 1px;
}
.react-draggable {
  height: 0px;
}
