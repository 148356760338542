.provider_heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #3c3c3c;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.provider_main {
  background-image: url("../../../public/imgs/background_2x.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
  padding: 30px 0px;
}

.new_care_services > .carousel {
  width: 315px;
  height: 254px;
  margin: 0px auto 20px;
  background-color: #ffffff;
  padding: 25px 0px 20px;
  border: 1px solid #d9d9d9;
  box-shadow: 4px 4px 15px rgba(30, 30, 30, 0.05);
}

.new_care_services > .carousel > .carousel-inner {
  height: 100%;
}

.new_care_services > .carousel > .carousel-inner > .carousel-item {
  height: 100%;
}

.provide_services_title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #3c3c3c !important;
}

.bg_CN {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.provider_para {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #616161;
  margin-bottom: 0px;
  max-width: 32ch;
}

.bariatric_title {
  font-size: 17px !important;
}

.bariatric_span {
  font-weight: 400;
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .provide_services_title {
    font-size: 22px;
  }

  .new_care_services > .carousel {
    width: 388px;
  }

  .bariatric_title {
    font-size: 20px !important;
  }
  .provider_heading {
    font-size: 24px;
  }
}

@media screen and (min-width: 768px) {
  .provider_main {
    padding: 60px 0px;
  }
  .new_care_services {
    display: flex;
    justify-content: center;
  }
  .new_care_services > .carousel {
    margin: 0px;
  }
  .upper_left_carousel {
    border-radius: 15px 0px 0px 0px;
  }

  .upper_right_carousel {
    border-radius: 0px 15px 0px 0px;
  }

  .bottom_left_carousel {
    border-radius: 0px 0px 0px 15px;
  }

  .bottom_right_carousel {
    border-radius: 0px 0px 15px 0px;
  }
  .provider_heading {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  .upper_left_carousel {
    border-radius: 15px 15px 0px 0px;
  }

  .bottom_right_carousel {
    border-radius: 0px 0px 15px 15px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .new_care_services {
    gap: 7px;
  }
  .serives_new_ {
    margin-bottom: 7px;
  }
  .new_care_services > .carousel {
    width: 250px;
  }
  .bariatric_title {
    font-size: 14px !important;
  }
  .provider_para {
    font-size: 12px;
  }
  .provide_services_title {
    font-size: 16px;
  }
  .provider_heading {
    font-size: 30px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1124px) {
  .new_care_services {
    gap: 20px;
  }
  .serives_new_ {
    margin-bottom: 20px;
  }
  .provider_heading {
    font-size: 36px;
  }
}

@media screen and (min-width: 1125px) {
  .new_care_services {
    gap: 30px;
  }
  .serives_new_ {
    margin-bottom: 30px;
  }
  .new_care_services > .carousel {
    width: 350px;
  }
  .provide_services_title {
    font-size: 22px;
  }
  .bariatric_title {
    font-size: 20px !important;
  }
  .provider_heading {
    font-size: 36px;
  }
}
