÷
.advance_footer_div {
  background-color: #e7e7e7;
}

.footer_icon_img {
  width: 20px;
  height: 20px;
  margin-right: 12px;
}

.footer_logo1_img {
  width: 199px;
  height: 53px;
  margin-bottom: 15px;
}

.footer_logo2_img {
  width: 236px;
  height: 53px;
  margin-bottom: 15px;
}

.footer_logo3_img {
  width: 132px;
  height: 53px;
  margin-bottom: 15px;
}

.footer_metaTags_div {
  margin-top: 12px;
}

.footer_meta_imgs {
  width: 22px;
  height: 22px;
  margin-right: 18px;
  cursor: pointer;
}

.footer_vector_div {
  cursor: pointer;
  height: 6px;
  width: 12px;
  margin-right: 5px;
}

.footer_vector_img {
  height: auto;
  width: 100%;
}

.toggle_vector_img {
  height: auto;
  width: 100%;
  transform: scaleY(-1);
}

.underline_img {
  height: 1px;
  width: 73px;
}

.footer_anchor {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #616161;
  margin-bottom: 0%;
}

.footer_anchor:hover {
  color: #dd0b09;
}

@media screen and (min-width: 768px) {
  .footer_anchor_web {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #616161 !important;
    margin-bottom: 8px;
  }
}

.hpt_anchor {
  margin-bottom: 8px;
}

.showOnMap_anchor {
  margin-bottom: 3px;
}

.footer_para {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #616161;
  margin-top: 15px;
  margin-bottom: 10px;
}

.otherInfo_text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #dd0b09;
  margin-bottom: 12px;
}

.footer_contact_div {
  margin-bottom: 15px;
  margin-top: 7px;
}

.footer_Map_div {
  margin-bottom: 15px;
}

@media screen and (min-width: 1150px) {
  .advance_footer {
    width: 1110px;
    height: 296px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding-top: 43px;
    column-gap: 30px;
    margin: auto;
  }
}

@media screen and (min-width: 992px) and (max-width: 1149px) {
  .advance_footer {
    width: 980px;
    height: 296px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding-top: 43px;
    column-gap: 15px;
    margin: auto;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .advance_footer {
    width: 750px;
    height: 296px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding-top: 43px;
    column-gap: 10px;
    margin: auto;
  }
  .footer_logo1_img {
    width: 150px;
  }
  .footer_logo2_img {
    width: 150px;
  }
  .footer_icon_img {
    margin-right: 6px;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .advance_footer {
    display: grid;
    padding-top: 22px;
    margin: auto;
  }
  .footer_sec1 {
    grid-column: span 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer_sec4 {
    grid-area: 2/1/3/3;
  }
  .footer_para {
    text-align: center;
    max-width: 52ch;
    margin-top: 0%;
    margin-bottom: 20px;
  }
  .otherInfo_text {
    font-size: 18px;
    cursor: pointer;
  }
  .footer_sec5 {
    grid-area: 4/1/4/3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 18px;
  }
  .footer_logo1_img {
    width: 217.6px;
    margin-bottom: 13px;
  }
  .footer_logo2_img {
    width: 108.49px;
    margin-left: 32px;
    margin-top: 18px;
  }
  .footer_logo3_img {
    width: 149px;
    margin-left: 32px;
    margin-top: 18px;
  }
  .footer_ShowOnMap_mob_div {
    margin-bottom: 13px;
  }
  .footer_metaTags_mob_div {
    justify-content: space-between;
    column-gap: 20px;
  }
  .footer_meta_imgs {
    margin-right: 0%;
  }
  .hpt_anchor {
    padding: 10px 20px;
    margin-bottom: 0%;
  }
  a.hpt_anchor_active:hover {
    color: white !important;
  }
  .hpt_anchor_active {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #ffffff !important;
    background-color: #616161 !important;
    margin-bottom: 0px;
  }
  .footer_mailTo_div {
    display: flex;
  }
  .footer_contactnum_div {
    display: flex;
    /* margin-top: 7px; */
  }
}

@media screen and (min-width: 575px) and (max-width: 767px) {
  .advance_footer {
    width: 560px;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
  }
}

@media screen and (min-width: 425px) and (max-width: 574px) {
  .advance_footer {
    width: 410px;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .advance_footer {
    width: 370px;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5px;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .advance_footer {
    width: 315px;
    grid-template-columns: repeat(2, 155px);
    column-gap: 5px;
  }
  .footer_logo3_img {
    margin-left: 21px;
  }
  .footer_logo2_img {
    margin-left: 21px;
  }
  .footer_icon_img {
    width: 15px;
    margin-right: 6px;
  }
  .last_screen_mail_anchor {
    font-size: 10px;
  }
}

@media screen and (min-width: 768px) {
  .footer_vector_div {
    display: none;
  }
  .footer_sec5 {
    display: none;
  }
  .footer_bottom_mob {
    display: none !important;
  }
  .footer_bottom_web {
    height: 30px;
    background-color: #616161;
  }
  .footer_bottom_para_web {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 0%;
    column-gap: 4px;
  }
  .footer_yellow_font {
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: #fddb31;
  }
  .footer_blue_text_web {
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: #00bfff !important;
  }
  .footer_blue_text_web:hover {
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: #00bfff !important;
  }
  .footer_blue_text_web a {
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: #00bfff;
  }
}

@media screen and (max-width: 767px) {
  .footer_ShowOnMap_div {
    display: none !important;
  }
  .footer_otherInfo_div {
    border-bottom: 1px solid #b8b8b8;
  }
  .footer_metaTags_div {
    display: none !important;
  }
  .hide_HPT {
    display: none !important;
  }
  .HPT_anchor_div {
    background-color: #ffffff;
    width: 100%;
  }
  .footer_bottom_web {
    display: none !important;
  }
  .footer_bottom_mob {
    margin-top: 15px;
    height: 30px;
    background-color: #616161;
  }
  .footer_bottom_para_mob {
    gap: 2px;
    margin-bottom: 0%;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: #ffffff;
  }
  .footer_blue_text_mob {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #00bfff !important;
  }
  .footer_blue_text_mob :hover {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #00bfff !important;
  }
  .footer_blue_text_mob a{
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #00bfff !important;
  }
}

@media screen and (max-width: 375px) and (min-width: 320px) {
  .footer_bottom_para_mob {
    font-size: 11px;
  }
  .footer_blue_text_mob {
    font-size: 11px;
  }
  .footer_blue_text_mob a {
    font-size: 11px;
  }
  .specific_height {
    height: 30px;
  }
}
