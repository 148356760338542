.mobile_services_page {
  background-image: url("../../../public/imgs/background_2x.webp");
}

/* *********************************************************************************************** */

.LaparoscopicBannerImage {
  background-image: url("../../../src/component/Assets/images/_advance_laparoscopic_surgery_mobile_banner_2x.webp");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 270px !important;
  margin-top: 70px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  padding: 5% 6%;
  z-index: -1;
}
.EndocrineBannerImage {
  background-image: url("../../../src/component/Assets/images/endocrine_surgery_banner_mobile_2x.webp");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 270px !important;
  margin-top: 70px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  padding: 5% 6%;
  z-index: -1;
}
.VascularBannerImage {
  background-image: url("../../../src/component/Assets/images/_vascular_surgery_banner_mobile_2x.webp");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 270px !important;
  margin-top: 70px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  padding: 5% 6%;
  z-index: -1;
}
.BariatricBannerImage {
  background-image: url("../../../src/component/Assets/images/_laparoscopic_bariatric_surgery_banner_mobile_2x.webp");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 270px !important;
  margin-top: 70px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  padding: 5% 6%;
  z-index: -1;
}

.OncologicalBannerImage {
  background-image: url("../../../src/component/Assets/images/cancer_surgery_banner_mobile_2x.webp");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 270px !important;
  margin-top: 70px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  padding: 5% 6%;
  z-index: -1;
}

.HealthcareBannerImage {
  background-image: url("../../../src/component/Assets/images/women_s_healthcare_clinic_mobile_banner_2x.webp");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 270px !important;
  margin-top: 70px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  padding: 5% 6%;
  z-index: -1;
}

.services_heading {
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: white;
}

.book_button {
  background-color: #fddb31;
  padding: 3% 7%;
  border: 0px;
  border-radius: 5px;
  cursor: pointer;
}
.book_button:hover {
  background-color: #e2c11c;
}

.services_body_box {
  border: 1px solid #d9d9d9;

  box-shadow: 4px 4px 15px rgba(30, 30, 30, 0.05);
}

.services_box_button {
  background-color: #fddb31;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding: 2% 5%;
  border: 0px;
  border-radius: 5px;
  font-family: "Roboto";
  height: 42px;
  width: 124px;
  color: #3c3c3c;
}
.services_box_button:hover {
  background-color: #e2c11c !important;
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .services_body_box {
    width: 388px;
    height: 300px;
    gap: 0% !important;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
  }
  .services_body {
    padding: 5% 5%;
  }
  .services_box_heading_bariatric {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #3c3c3c;
    padding-top: 20px !important;
  }
  .services_box_heading {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: #3c3c3c;
    padding-top: 20px !important;
  }
  .services_box_img_div {
    width: 175px;
    height: 108px;
  }
  .services_box_img {
    width: 175px;
    height: 108px;
  }
  .services_box_description {
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    color: #3c3c3c;
    max-width: 36ch;
    padding-top: 0% !important;
  }
  .services_box_button {
    margin-bottom: 20px !important;
  }
  .services_heading_body {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;
    color: #3c3c3c;
  }
  .services_description_body {
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    padding-bottom: 3%;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .services_body_box {
    width: 335px;
    height: 300px;
    gap: 0% !important;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
  }
  .services_body {
    padding: 5% 5%;
  }
  .services_box_heading {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: #3c3c3c;
    padding-top: 20px !important;
  }
  .services_box_heading_bariatric {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #3c3c3c;
    padding-top: 20px !important;
  }
  .services_box_img_div {
    width: 175px;
    height: 108px;
  }
  .services_box_img {
    width: 175px;
    height: 108px;
  }
  .services_box_description {
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    color: #3c3c3c;
    max-width: 36ch;
    padding-top: 0% !important;
  }
  .services_box_button {
    margin-bottom: 20px !important;
  }
  .services_heading_body {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;
    color: #3c3c3c;
  }
  .services_description_body {
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    padding-bottom: 3%;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .services_body_box {
    width: 305px;
    height: 300px;
    gap: 0% !important;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
  }
  .services_body {
    padding: 5% 2%;
  }
  .services_box_heading_bariatric {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 28px;
    text-align: center;
    color: #3c3c3c;
    padding-top: 20px !important;
  }
  .services_box_heading {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #3c3c3c;
    padding-top: 20px !important;
  }
  .services_box_img_div {
    width: 175px;
    height: 108px;
  }
  .services_box_img {
    width: 175px;
    height: 108px;
  }
  .services_box_description {
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    color: #3c3c3c;
    max-width: 36ch;
    padding-top: 0% !important;
  }
  .services_box_button {
    margin-bottom: 20px !important;
  }
  .services_heading_body {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;
    color: #3c3c3c;
  }
  .services_description_body {
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    padding-bottom: 3%;
  }
}

.services_heading_body {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  line-height: 42px;
  text-transform: uppercase;
  color: #3c3c3c;
}
.services_description_body {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  color: #616161;
  padding: 2% 2%;
  padding-bottom: 5%;
}

.services_box_heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  color: #3c3c3c;
}

.services_box_description {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  text-align: center;
  color: #616161;
}

@media screen and (min-width: 768px) {
  .s_box_1 {
    display: flex;
  }
  .s_box_2 {
    display: flex;
  }
  .services_body {
    padding: 40px 0px 50px;
  }
  .services_box_img {
    width: 100%;
    height: auto;
  }
  .services_upper_left {
    border-radius: 15px 0px 0px 0px;
  }
  .services_upper_right {
    border-radius: 0px 15px 0px 0px;
  }
  .services_bottom_right {
    border-radius: 0px 0px 15px 0px;
  }
  .services_bottom_left {
    border-radius: 0px 0px 0px 15px;
  }
}

@media screen and (min-width: 1125px) {
  .s_box_1 {
    width: 1110px;
    gap: 30px;
    margin: 10px auto 30px;
  }
  .s_box_2 {
    width: 1110px;
    gap: 30px;
    margin: 10px auto;
  }
  .services_box_heading_bariatric {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #3c3c3c;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 800px) {
  .services_box_heading_bariatric > .bariatric_span {
    font-size: 14px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1124px) {
  .services_box_heading_bariatric {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #3c3c3c;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1124px) {
  .services_box_heading_bariatric {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #3c3c3c;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 992px) {
  .services_body_box {
    width: 350px;

    padding: 25px 0px 20px;
  }
  .services_heading_body {
    font-size: 36px;
    margin-bottom: 20px;
  }
  .services_description_body {
    font-size: 22px;
    margin: 0px auto 45px;
    max-width: 1200px;
    width: 100%;
    padding: 0% 2%;
  }
  .services_box_heading {
    font-size: 22px;
    margin-bottom: 20px;
  }

  .services_box_img_div {
    width: 175px;
    height: 100%;
    margin-bottom: 15px;
  }
  .services_box_description {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .dp_1 {
    max-width: 28ch;
  }
  .dp_2 {
    max-width: 26ch;
  }
  .dp_3 {
    max-width: 28ch;
  }
  .dp_4 {
    max-width: 28ch;
  }
  .dp_5 {
    max-width: 34ch;
  }
  .dp_6 {
    max-width: 32ch;
  }
}

@media screen and (min-width: 992px) and (max-width: 1124px) {
  .s_box_1 {
    width: 980px;
    gap: 10px;
    margin: 0px auto 10px;
  }
  .s_box_2 {
    width: 980px;
    margin: auto;
    gap: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .s_box_1 {
    width: 750px;
    gap: 7.5px;
    margin: 0px auto 7.5px;
  }
  .s_box_2 {
    width: 750px;
    margin: auto;
    gap: 7.5px;
  }
  .services_body_box {
    width: 250px;
    height: 295px;
    padding: 12px 0px 6px;
  }
  .services_heading_body {
    font-size: 32px;
    margin-bottom: 20px;
  }
  .services_description_body {
    font-size: 18px;
    margin: 0px auto 45px;
    max-width: 90ch;
  }
  .services_box_heading {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .services_box_heading_bariatric {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    color: #3c3c3c;
    margin-bottom: 15px;
  }
  .services_box_img_div {
    width: 175px;
    height: 108px;
    margin-bottom: 15px;
  }
  .services_box_description {
    font-size: 13px;
    margin-bottom: 15px;
  }
  .dp_1 {
    max-width: 28ch;
  }
  .dp_2 {
    max-width: 26ch;
  }
  .dp_3 {
    max-width: 28ch;
  }
  .dp_4 {
    max-width: 28ch;
  }
  .dp_5 {
    max-width: 28ch;
  }
  .dp_6 {
    max-width: 30ch;
  }
}

@media screen and (max-width: 767px) {
  .services_body_box {
    justify-content: space-between;
  }
  .services_upper_left {
    border-radius: 15px 15px 0px 0px;
  }
  .services_bottom_right {
    border-radius: 0px 0px 15px 15px;
  }
}

@media screen and (min-width: 601px) and (max-width: 767px) {
  .services_upper_left {
    border-radius: 15px 15px 0px 0px;
  }
  .services_upper_right {
    border-radius: 0px;
  }
  .services_bottom_right {
    border-radius: 0px 0px 15px 15px;
  }
  .services_bottom_left {
    border-radius: 0px;
  }
}

@media screen and (max-width: 600px) {
  .zebra_sec_bullets {
    padding-left: 0% !important;
  }
}
