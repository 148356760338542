.accivement_main {
  width: 100%;
}
.accivement_data {
  width: 100%;
  margin: 30px auto 0px;
  max-width: 1500px;
}

.accivement_heaading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #3c3c3c;
  margin-bottom: 0px !important;
}
@media screen and (max-width: 600px) {
  .accivement_heaading {
    text-transform: uppercase;
  }
}
.accivemnet_box_destop {
  width: 34%;
}
.accivement_cricle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.accviment_box {
  max-width: 139px;
  width: 100%;
  height: 120px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(214, 214, 214, 0.25);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  padding: 13px 5px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  margin: 10px auto;
  color: #3c3c3c;
}
.accviment_box_black {
  max-width: 139px;
  width: 100%;
  height: 120px;
  background: #3c3c3c;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  padding: 13px 5px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  margin: 10px auto;

  color: #3c3c3c;
}
.accviment_box span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  margin-bottom: 16px;
}
.box_heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  margin-bottom: 15px;
  color: #3c3c3c;
}
.box_heading_yellow {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  margin-bottom: 15px;
  color: #fddb31;
}
.box_line {
  width: 28.92px;
  height: 0px;
  border: 2px solid #fddb31;
  margin-top: -17px;
}
.box_para {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 146.19%;
  margin-bottom: 0px;

  text-align: center;

  margin-top: 10px;
  color: #616161;
}
.box_para_yellow {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 146.19%;
  margin-bottom: 0px;

  text-align: center;

  margin-top: 10px;
  color: #ffffff;
}
.accivement_row {
  display: flex;
  justify-content: center;
  margin: 25px 0;
  max-width: 900px;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .order_div {
    order: 4;
  }
}

@media screen and (min-width: 600px) {
  .accivemnet_box_destop {
    width: 17%;
  }
  .accivement_cricle {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0px auto;
  }
  .accivement_row {
    width: 100% !important;
  }

  .accivement_heaading {
    font-weight: 700;
    font-size: 30px;
    line-height: 42px;
    color: #3c3c3c;
    color: #3c3c3c;
    margin-bottom: 30px;
  }
  .accivement_main {
    width: 100%;
    padding: 0 2%;
  }
}
@media screen and (min-width: 1100px) {
  .accviment_box {
    max-width: 160px;
    width: 100%;
    height: 160px;
  }
  .accviment_box_black {
    max-width: 160px;
    width: 100%;
    height: 160px;
  }
  .accivement_heaading {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: #3c3c3c;
    margin-bottom: 30px;
  }
  .accivemnet_box_destop {
    width: 21%;
  }
  .box_heading_yellow {
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    color: #fddb31;
    margin-bottom: 15px;
    color: #fddb31;
  }
  .box_para_yellow {
    font-weight: 400;
    font-size: 15px;
    line-height: 100.19%;
    margin-bottom: 0px;
    text-align: center;
    margin-top: 10px;
    color: #ffffff;
  }
  .box_heading {
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    color: #fddb31;

    margin-bottom: 15px;
    color: #3c3c3c;
  }
  .accviment_box span {
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 16px;
  }
  .box_para {
    font-weight: 400;
    font-size: 15px;
    line-height: 100.19%;
    margin-bottom: 0px;
    text-align: center;
    margin-top: 10px;
    color: #616161;
  }
  .box_line {
    width: 63.92px;
    height: 0px;
    border: 2px solid #fddb31;
    margin-top: -17px;
  }
}

.accivement_cricle > .row > * {
  padding-right: 0% !important;
  padding-left: 0% !important;
}

.accivement_row {
  gap: 30px;
}
