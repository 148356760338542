.searchResult_container {
  position: relative;
  top: 80px;
  padding: 20px 0px 60px;
  background-color: #ffffff;
}

.searchResult_content_div {
  width: 310px;
  margin: 0px auto;
}
.nomatch{
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  color: #616161;
}

.seachResult_heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #3c3c3c;
  margin-bottom: 20px;
}

.results_heading:hover {
  color: #fddb31;
  cursor: pointer;
}

.searchResult_form {
  display: flex;
  margin-bottom: 35px;
}

.cardData {
  margin-bottom: 0px;
  list-style-position: inside !important;
  padding-left: 1% !important;
}

.cardItem::marker {
  color: #fddb31;
}

@media screen and (max-width: 424px) {
  .searchResult_form {
    justify-content: space-between;
  }
}

@media screen and (max-width: 424px) {
  .searchResult_form {
    justify-content: space-between;
  }
}

.searchResult_input {
  padding-left: 20px;
  width: 225px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 4px 4px 15px rgba(30, 30, 30, 0.05);
  border-radius: 5px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 145%;
  color: #6b6b6b;
}

.searchResult_input::placeholder {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 145%;
  color: #6b6b6b;
}

.searchResult_input:focus {
  outline: 1px solid #fddb31;
}

.searchResult_btn {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #3c3c3c;
  padding: 15px 20px;
  background-color: #fddb31;
  border: none;
  border-radius: 5px;
}

@media screen and (min-width: 375px) and (max-width: 767px) {
  .searchResult_btn {
    padding: 15px 30px;
  }
}

.results_toShow_div {
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid #d9d9d9;
}

.results_heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #3c3c3c;
  margin-bottom: 20px;
}

.results_para {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #616161;
  margin-bottom: 0px;
}

.cardItem {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #616161;
  margin-bottom: 0px;
}

.searchResult_pagination_div > .destop_pagination_view {
  padding: 0% !important;
}

.searchResult_pagination_div
  > .destop_pagination_view
  > .blog_value
  > .blog_dropdwon
  > .select_vlaue_blog {
  margin-left: 7px;
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .searchResult_content_div {
    width: 360px;
  }
  .searchResult_input {
    width: 250px;
  }
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .searchResult_content_div {
    width: 388px;
  }
  .searchResult_input {
    width: 275px;
  }
  .searchResult_form {
    gap: 9px;
  }
}

@media screen and (min-width: 768px) {
  .searchResult_container {
    top: 100px;
    padding: 50px 0px 42px;
  }
  .searchResult_pagination_div > .destop_pagination_view > .paginationBttns {
    gap: 5px;
  }
  .seachResult_heading {
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 30px;
  }
  .searchResult_input {
    width: 624px;
  }
  .searchResult_form {
    gap: 15px;
    margin-bottom: 50px;
  }
  .results_heading {
    font-size: 24px;
    line-height: 28px;
  }
  .results_para {
    font-size: 18px;
    line-height: 145%;
  }
  .cardItem {
    font-size: 18px;
    line-height: 145%;
  }
  .results_toShow_div {
    padding-bottom: 35px;
    margin-bottom: 35px;
  }
  .searchResult_btn {
    padding: 15px 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .searchResult_content_div {
    width: 750px;
  }
  .searchResult_pagination_div > .destop_pagination_view > .paginationBttns {
    padding-right: 10px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1124px) {
  .searchResult_content_div {
    width: 980px;
  }
}

@media screen and (min-width: 1125px) {
  .searchResult_content_div {
    width: 1110px;
  }
}
