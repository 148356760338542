@keyframes blinkingBackground {
  0% {
    background-color: 0.5;
  }

  25% {
    background-color: 0.7;
  }

  50% {
    opacity: 0.9;
  }

  75% {
    background-color: 1;
  }

  100% {
    background-color: rgb(152, 222, 152);
  }
}

.react-draggable {
  position: fixed;
  bottom: 115px;
  right: 35px;
  z-index: 999999999;
}
.react-draggable > img {
  border-radius: 120px;
  width: 70px;
  height: 70px;
  cursor: pointer;
  background-image: url(../Assets/images/whatsapp.png);
  background-repeat: no-repeat;
  background-origin: content-box;
  animation: blinkingBackground 1s infinite !important;
  background-position: center;
}

.only_icon_div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.only_icon_div > img {
  height: 18px;
  width: 18px;
}
.search_form {
  position: relative;
  width: 100%;
}

.suggestion_text {
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: #3c3c3c;
}

.header_drop p {
  padding: 0px 0px;
}

.header_drop1 {
  display: none;
}
.new_navbar {
  display: none;
}
.navbar-expand-md .navbar-nav-scroll {
  overflow: visible;
  width: 100%;
  justify-content: flex-end;
  margin-right: 20px !important;
}
.navbar > .container-fluid {
  flex-direction: inherit !important;
  padding-left: 0px;
  width: 93%;
  margin: 0px auto;
}
.header_btn1 {
  width: 175px;
  height: 35px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #ffffff;

  text-align: center;
  border: none !important;
  margin-top: 4px;
  background: #3c3c3c;
  border-radius: 5px;
}
.header_btn {
  display: none;
}
.header_link {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 200%;
  color: #3c3c3c;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 0px;
}
.header_link_active {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 200%;
  color: #3c3c3c;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 0px;
  border-bottom: 2px solid #fddb31;
}
.navbar {
  position: fixed;
  top: 30px;
  z-index: 99;
  width: 100%;
}

.header_link_1 {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 200%;
  color: #3c3c3c;
  margin-top: 5px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header_link_1_active {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 200%;
  color: #3c3c3c;
  margin-top: 5px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #fddb31;
}

.ticker-wrapper-h {
  display: flex;
  position: relative;
  overflow: hidden;
  background: #fddb31;
  height: 35px;
  position: fixed;
  top: 0px;
  z-index: 99;
  width: 100%;
}

.news-ticker-h {
  display: flex;
  margin: 0;
  padding: 0;
  padding-left: 90%;
  z-index: 999;
  justify-content: center;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: tic-h;
  height: 35px;
  animation-duration: 30s;
}

.news-ticker-h p {
  width: 200vw !important;
  font-weight: 900;
  font-size: 12px;
  line-height: 11px;
  text-align: center;
  color: #dd0b09;
  display: flex;
  align-items: center;
  height: 100%;
  margin-bottom: 0px;
}

@keyframes tic-h {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.services_header {
  display: flex;
  align-items: center;
}
.header_link_drop {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 200%;
  color: #3c3c3c;
  margin-top: 5px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.header_drop {
  position: absolute;
  top: 62px;
  z-index: 888;
}
.header_drop p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin: 3px 0px;
  color: #3c3c3c;
  padding: 9px 0px;
  border-bottom: 1px solid #ffffff;
  width: 100%;
}
.header_drop p:hover {
  color: #eeeeee !important;
  background-color: #616161 !important;
}
.header_drop h5 {
  width: 100%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin: 3px 0px;
  color: #3c3c3c;
  padding: 9px 0px;
  border-bottom: 1px solid #ffffff;
}
.header_drop h5:hover {
  color: #eeeeee !important;
  background-color: #616161 !important;
}
.drop_img {
  width: 10px;
  height: 6px;
  background-image: url("../Assets/images/services/NavImages/dropImg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-left: 10px;
  margin-top: 5px;
  margin-left: 4px;
}
.drop_img1 {
  width: 10px;
  height: 6px;
  background-image: url("../Assets/images/services/NavImages/dropImg222.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-left: 10px;
  margin-top: 5px;
}
.drop_img_mob {
  width: 10px;
  height: 10px;
  background-image: url("../Assets/images/yellowdrop.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-left: 10px;
  margin-top: 5px;
  margin-left: 4px;
  display: none;
}
.drop_img1_mob {
  width: 10px;
  height: 10px;
  background-image: url("../Assets/images/yellowtop.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-left: 10px;
  margin-top: 5px;
  display: none;
}

@media only screen and (max-width: 767px) {
  .navbar-toggler-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    background-image: url("../Assets/images/services/NavImages/menuIcon.png") !important;
  }

  .mob_btn {
    width: 30%;
    display: flex;
    justify-content: center;
  }
  .navbar-toggler {
    padding: 0% !important;
  }

  .nav-link {
    padding: 0px !important;
  }
  .nav-link_active {
    padding: 0px !important;
  }

  .navbar-toggler-icon1 {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 24px;
    height: 24px;
    background-image: url("../Assets/images/services/NavImages/x.png") !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }

  .navbar-toggler {
    border: none !important;
    outline: none !important;
    text-align: center;
  }

  button:focus:not(:focus-visible) {
    outline: none !important;
  }

  .navbar-toggler:focus {
    box-shadow: none !important;
  }

  .dropdown-toggle::after {
    display: inline-block;
    background-image: url("../Assets/images/cross.png") !important;
    margin-left: auto !important;
    width: 100%;
    display: inline-block;
    margin-left: 0.255em;

    content: "";
  }

  .navbar > .container-fluid {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-around !important;
  }

  .navbar-collapse {
    color: white;
    transition: 0.5s;
  }
  .collapsing {
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
  }
  .collapas {
    background-color: black;
  }

  .header_link {
    font-family: "Roboto" !important;
    font-style: normal !important;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #3c3c3c !important;
    border-bottom: 1px solid #818181 !important;
    padding: 10px 6px;
    width: 95%;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header_link_active {
    font-style: normal !important;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;

    border-bottom: 1px solid #818181 !important;
    padding: 10px 6px;
    width: 95%;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header_link_drop {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #3c3c3c !important;
    border-bottom: none !important;
    padding: 10px 6px;
    width: 95%;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header_link_1 {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #3c3c3c !important;
    border-bottom: none !important;
    padding: 10px 6px;
    width: 95%;
    margin: 0px auto;
  }
  .header_link_1_active {
    font-family: "Roboto" !important;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    border-bottom: none !important;
    padding: 10px 6px;
    width: 95%;
    margin: 0px auto;
    text-decoration: none !important;
  }

  .header_drop {
    position: initial;
  }

  .header_drop1 {
    display: none;
  }

  .header_drop p {
    font-style: normal;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    color: #3c3c3c;
    width: 100%;
    padding-left: 4%;
  }
  .header_drop p:hover {
    background-color: #3c3c3c;
    color: #ffffff;
  }
  .header_drop h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #3c3c3c;
    width: 100%;
    padding-left: 4%;
  }
  .header_drop h5 :hover {
    background-color: #3c3c3c;
    color: #ffffff;
  }
  .drop_img {
    display: none;
  }
  .drop_img1 {
    display: none;
  }

  .drop_img_mob {
    width: 12px;
    height: 6px;
    background-image: url("../Assets/images/services/NavImages/dropImg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    right: 20px;
    display: block;
  }

  .drop_img1_mob {
    width: 12px;
    height: 6px;
    background-image: url("../Assets/images/services/NavImages/dropImg222.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    right: 20px;
    display: block;
  }

  .me-auto {
    margin-right: auto !important;
    z-index: 333333 !important;
  }
  .navbar > .container-fluid {
    flex-direction: row-reverse !important;
    padding-left: 0px;
    width: 100%;
    margin: 0px auto;
  }

  .collapas {
    background-color: #ffffff !important;
    width: 95%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 3;
    border-radius: 0px 0px 12px 12px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 5px 5px;
    padding-top: 20px;
    transition: all 300ms ease-in-out;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: column;
    width: 70%;
    justify-content: center;
    overflow: scroll !important;
  }

  .delDiv {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 25px;
    z-index: 1111;
  }

  .delCircle {
    height: 80px;
    width: 80px;
    background-color: #fddb31;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.2s; /* Animation */
  }
  .delCircle:hover {
    transform: scale(1.3);
  }

  .delImage {
    position: absolute;
    height: 40%;
    width: 40%;
  }
  @keyframes blinkingBackground {
    0% {
      background-color: 0.5;
    }

    25% {
      background-color: 0.7;
    }

    50% {
      opacity: 0.9;
    }

    75% {
      background-color: 1;
    }

    100% {
      background-color: rgb(152, 222, 152);
    }
  }

  .whatsapp_button {
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    position: absolute;
  }

  .back-to-top {
    position: fixed;
    top: 120px;
    width: 100%;
    color: white;
    cursor: pointer;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 566666;
  }

  .top_btn {
    background: rgba(0, 0, 0, 0.8);
    border-radius: 19px;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 34px;
    border: none;
    outline: none;
    padding: auto;
    font-weight: 600;
    color: #ffffff;
    text-transform: capitalize;
    padding: 2px 20px;
    z-index: 5555;
  }

  .top_btn img {
    width: 6px;
    height: 6px;
    object-fit: contain;
    margin-left: 8px;
  }
  .droplogo_img {
    width: 30px;
    height: 30px;
    object-fit: contain;
    width: 85%;
  }
  .dropcross_img {
    cursor: pointer;
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
  .new_navbar {
    display: flex;
    padding-top: 10px;
    padding-left: 3%;
  }
  .services_header {
    display: flex;
    align-items: center;
  }
}

.search_icon {
  height: auto;
  width: 25px;
}

.search_input {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #3c3c3c;
  border-top-style: hidden;
  border-left-style: hidden;
  border-right-style: hidden;
  border-bottom-style: hidden;
  background-color: transparent;
  overflow: hidden;
}

.search_input:focus {
  outline: none;
}

.search_form {
  border-bottom: 1px solid #3c3c3c;
}

.search_input::placeholder {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #3c3c3c;
}

@media only screen and (max-width: 350px) {
  .logo_img {
    object-fit: contain;
    height: 32px;
    padding-left: 4%;
    width: 80%;
  }
  .navbar-toggler {
    padding-left: 5% !important ;
  }
}
.upperDiv {
  height: 100px;
  width: 100px;
  background-color: blue;
  position: relative;
}

/* **************************************************************************************************************** */

.search_div {
  width: 100%;
  position: relative;
}
.search_input {
  width: 100%;
}
.search_form {
  width: 100%;
  margin: auto;
}

@media screen and (max-width: 1023px) {
  .header_btn1 {
    display: none;
  }
}

.Advance_header > .navbar > .container {
  margin: auto !important;
  flex-direction: row-reverse;
  max-width: 100%;
}

.nav_padding_null > .navbar > .container {
  margin: auto !important;
  max-width: 100%;
}

@media screen and (max-width: 767px) {
÷

  .navbar-brand {
    margin-right: 0%;
    padding-top: 0% !important;
    padding-bottom: 0% !important;
  }

  .logosWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .buttonsWrapper {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-right: 10px;
  }
  .logo_img {
    width: 191px;
    height: 43px;
    margin-left: 15%;
    object-fit: contain;
  }
  .logo_img_1 {
    width: 170px;
    height: 41px;
  }
  .logo_img_2 {
    width: 90px;
  }
  .md_search {
    display: none !important;
  }

  .max_logo_wrapper {
    width: 90%;
  }

  .auto_btn_wrapper {
    margin-right: 10px;
  }

  .full_width_search_form > .search_div > .search_form {
    max-width: 75% !important;
    margin: 0 !important;
  }

  .nav_padding_null > .navbar {
    --bs-navbar-padding-y: 0% !important;
    height: 50px;
  }

  .Advance_header > .navbar {
    --bs-navbar-padding-y: 0.5rem !important;
  }
}

@media screen and (max-width: 575px) and (min-width: 375px) {
  .logo_img_2 {
    width: 90px;
    height: 45px;
  }
  .logo_img_1 {
    padding-left: 0%;
    width: 170px;
    height: 41px;
  }
  /* .logosWrapper {
  } */
  /* .buttonsWrapper {
  } */
  .navbar-brand {
    margin-right: 0% !important;
  }
}

@media screen and (max-width: 425px) and (min-width: 375px) {
  .navbar {
    height: 50px;
    padding: 0% !important;
  }
  .navbar-brand {
    padding: 0% !important;
  }
  /* .logo_img_1 {
  } */
  .lower_icon_div {
    position: fixed;
    top: 78px;
    width: 100%;
    height: 50px;
    z-index: 9;
  }
}

@media screen and (max-width: 375px) and (min-width: 320px) {
  .logo_img_2 {
    width: 90px;
    height: 45px;
  }
  .logo_img_1 {
    padding-left: 0%;
    width: 149px;
    height: 41px;
  }
  /* .logosWrapper {
  } */
  /* .buttonsWrapper {
  } */
  .navbar-brand {
    margin-right: 0% !important;
  }
  .navbar {
    height: 50px;
    padding: 0% !important;
  }
  .navbar-brand {
    padding: 0% !important;
  }
  /* .Advance_header > .navbar > .container {
  } */
  .lower_icon_div {
    position: fixed;
    top: 78px;
    width: 100%;
    height: 50px;
    z-index: 9;
  }
}

@media screen and (min-width: 425px) {
  .lower_icon_div {
    position: fixed;
    top: 78px;
    width: 100%;
    height: 50px;
    z-index: 9;
  }
}

.lower_logo_1 {
  height: 41px;
  width: 153px;
}

.lower_logo_2 {
  height: 45px;
  width: 90px;
}

@media screen and (min-width: 767px) {
  .header_drop {
    background-color: #eeeeee;
    width: 300px;
    box-shadow: 4px 4px 17px rgba(0, 0, 0, 0.06);
    border-radius: 0px;
  }

  .header_drop p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding: 10px 10px;
    color: #3c3c3c;
    border-bottom: 1px solid #818181;
  }

  .header_drop h5 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding: 10px 10px;
    color: #3c3c3c;
    border-bottom: 1px solid #818181;
  }

  .header_drop > .nav-link {
    padding: 0% !important;
  }
}

@media screen and (min-width: 1330px) {
  .logo_img_1 {
    width: 191px;
    height: 43px;
  }
  .logo_img_2 {
    width: 191px;
    height: 43px;
  }
  .navbar-brand {
    padding-top: 0% !important;
    padding-bottom: 0% !important;
    margin-right: 0% !important;
    font-size: 0% !important;
  }
  .navbar {
    height: 65px !important;
    --bs-navbar-padding-y: 0% !important;
  }
  .md_search {
    max-width: 285px;
  }
  .header_link {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #3c3c3c;
  }
  .header_link_active {
    font-family: "Roboto" !important;
    font-style: normal !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #3c3c3c;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 0px;
    border-bottom: 2px solid #fddb31;
  }
  .header_link_1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #3c3c3c;
  }
  .header_btn1 {
    width: 175px;
    height: 35px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #ffffff;
    text-align: center;
    border: none !important;
    margin-top: 4px;
    background: #3c3c3c;
    border-radius: 5px;
  }
}

.navbar-nav-scroll {
  margin-top: 0% !important;
  margin-bottom: 0% !important;
}

@media screen and (min-width: 1370px) {
  .nav_padding_null > .navbar > .container {
    height: 65px !important;
    max-width: 95% !important;
    margin: auto !important;
    padding-right: 0% !important;
    padding-left: 0% !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1370px) {
  .nav_padding_null > .navbar > .container {
    height: 65px !important;
    max-width: 98% !important;
    margin: auto !important;
    padding-right: 0% !important;
    padding-left: 0% !important;
  }
}

@media screen and (min-width: 1170px) and (max-width: 1330px) {
  .logo_img_1 {
    width: 191px;
    height: 43px;
  }
  .logo_img_2 {
    width: 191px;
    height: 43px;
  }
  .navbar-brand {
    padding-top: 0% !important;
    padding-bottom: 0% !important;
    margin-right: 0% !important;
    font-size: 0% !important;
  }
  .navbar {
    height: 65px !important;
    --bs-navbar-padding-y: 0% !important;
  }
  .md_search {
    max-width: 200px;
  }
  .header_link {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #3c3c3c;
  }
  .header_link_active {
    font-family: "Roboto" !important;
    font-style: normal !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #3c3c3c;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 0px;
    border-bottom: 2px solid #fddb31;
  }
  .header_link_1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #3c3c3c;
  }
  .header_btn1 {
    width: 175px;
    height: 35px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #ffffff;
    text-align: center;
    border: none !important;
    margin-top: 4px;
    background: #3c3c3c;
    border-radius: 5px;
  }
  .nav-link {
    padding-right: 0% !important;
  }

  .navbar-nav-scroll {
    padding-right: 10px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1170px) {
  .navbar-brand {
    padding-top: 0% !important;
    padding-bottom: 0% !important;
    margin-right: 0% !important;
    font-size: 0% !important;
  }
  .navbar {
    height: 65px !important;
    --bs-navbar-padding-y: 0% !important;
  }
  .md_search {
    max-width: 140px;
  }
  .header_link {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #3c3c3c;
  }
  .header_link_active {
    font-family: "Roboto" !important;
    font-style: normal !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #3c3c3c;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 0px;
    border-bottom: 2px solid #fddb31;
  }
  .header_link_1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #3c3c3c;
  }
  .header_btn1 {
    width: 170px;
    height: 35px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #ffffff;
    text-align: center;
    border: none !important;
    margin-top: 4px;
    background: #3c3c3c;
    border-radius: 5px;
  }
  .nav-link {
    padding-right: 0% !important;
  }

  .navbar-nav-scroll {
    padding-right: 5px;
  }
}

@media screen and (min-width: 850px) and (max-width: 1170px) {
  .logo_img_1 {
    width: 170px;
    height: 43px;
  }
  .logo_img_2 {
    width: 170px;
    height: 43px;
  }
}

@media screen and (min-width: 768px) and (max-width: 850px) {
  .logo_img_1 {
    width: 140px;
    height: 43px;
  }
  .logo_img_2 {
    width: 140px;
    height: 43px;
  }
  .md_search {
    max-width: 140px;
  }
  .navbar-expand-md .navbar-nav-scroll {
    margin-right: 0px !important;
  }
}

@media screen and (min-width: 850px) and (max-width: 1024px) {
  .md_search {
    max-width: 140px;
  }
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
  .navbar-brand {
    padding-top: 0% !important;
    padding-bottom: 0% !important;
    margin-right: 0% !important;
    font-size: 0% !important;
  }
  .navbar {
    height: 65px !important;
    --bs-navbar-padding-y: 0% !important;
  }

  .header_link {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #3c3c3c;
  }
  .header_link_active {
    font-family: "Roboto" !important;
    font-style: normal !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #3c3c3c;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 0px;
    border-bottom: 2px solid #fddb31;
  }
  .header_link_1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #3c3c3c;
  }
  .nav-link {
    padding-right: 0% !important;
  }

  .navbar-nav-scroll {
    padding-right: 5px;
  }
}

.md_search > .search_div > .search_form {
  height: 24px;
  margin-left: 5px;
}

.md_search > .search_div > .search_form > .search_span {
  width: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.md_search > .search_div > .search_form > .search_span > img {
  width: 17px;
  height: 17px;
}
.max_logo_wrapper > .search_div > .search_form {
  height: 24px;
  margin-left: 5px;
}

.max_logo_wrapper > .search_div > .search_form > .search_span {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.max_logo_wrapper > .search_div > .search_form > .search_span > img {
  width: 18px;
  height: 18px;
}

@media screen and (max-width: 767px) {
  .www {
    height: 24px !important;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    width: 100%;
  }
  .hhh {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
  }
}

.serachCrossDiv {
  height: 24px;
  width: 24px;
  margin-left: auto;
}

.searchCrossButton {
  height: 24px;
  width: 24px;
  position: absolute;
  top: 0%;
  right: 0%;
  cursor: pointer;
}

@media screen and (min-width: 1400px) {
  .logo_img_1 {
    margin-right: 10px;
  }
  .logo_img_2 {
    margin-right: 17px;
  }
}

@media screen and (min-width: 768px) {
  .search_form {
    opacity: 0.6;
  }
}

.navbar-collapse {
  flex-basis: 44% !important;
}

.notVisible {
  visibility: hidden;
}
