.home_main {
  background-image: url("../../../public/imgs/homepage_desktop_banner_2x.webp");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  height: 652px;
  display: flex;
  align-items: center;
}

/* .home_main2 {
  background-image: url("../../../public/imgs/Rectangle\ 4559.png");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-top: 0px;
  height: 652px;
  width: 100vw;
  position: absolute;
  top: 0%;
  left: 0%;
  opacity: 0.91;
} */

÷
.home_text {
  width: 100%;
  margin: 0px auto;
}
.home_text h4 {
  font-style: normal;
  font-weight: 900;
  font-size: 28px;
  line-height: 28px;
  text-transform: uppercase;
  color: #fddb31;
  max-width: 315px;
  width: 100%;
}
.home_text h6 {
  font-family: "Roboto";
  font-style: normal;
  /* font-weight: 600; */
  font-size: 14px;
  line-height: 16px;
  color: #ffffff !important;
  width: 80%;
}
.home_text p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 146.19%;
  color: #ffffff;
  max-width: 325px;
  width: 90%;
}
.home_btn_div {
  display: flex;
  align-items: self-end;
}
.home_btn {
  width: 100%;
  max-width: 129px;
  height: 41px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: #fddb31;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: #3c3c3c;
  border: none;
  outline: none;
  padding: 10px 20px;
}
.home_btn:hover {
  background-color: #e2c11c;
}

@media screen and (min-width: 425px) and (max-width: 600px) {
  .home_data {
    margin: 0% auto;
    width: 90%;
    position: relative;
    top: 11%;
  }

  .home_text h6 {
    padding-top: 0%;
    /* text-transform: uppercase; */
    color: #ffffff !important;
    font-family: "Roboto";
    font-style: normal;
    /* font-weight: 600; */
    font-size: 18px;
    line-height: 23px;
    width: 100%;
    max-width: 30ch;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .home_data {
    margin: 0% auto;
    width: 90%;
    position: relative;
    top: 11%;
  }

  .home_text h6 {
    /* text-transform: uppercase; */
    color: #ffffff !important;
    font-family: "Roboto";
    font-style: normal;
    /* font-weight: 600; */
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    max-width: 33ch;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .home_data {
    margin: 0% auto;
    width: 90%;
    position: relative;
    top: 12%;
  }

  .home_text h6 {
    /* text-transform: uppercase; */
    color: #ffffff !important;
    font-family: "Roboto";
    font-style: normal;
    /* font-weight: 600; */
    font-size: 17px;
    line-height: 23px;
    width: 100%;
    max-width: 30ch;
  }
}

@media screen and (max-width: 600px) {
  .home_main {
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    height: 325px;
    display: flex;
    align-items: center;
  }

  /* .home_main2 {
    height: 325px;
  } */

 /* mobile view heading */
  /* .home_text h4 {
    display: none;
  } */

/* mobile view sub heading */
  /* .home_text p {
   display: none;
  } */
}

@media screen and (min-width: 600px) {
  .home_data {
    width: 73%;
    margin: 0px auto;
    height: 80%;
    position: relative;
    top: 11%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .home_main {
    padding-top: 0px;
  }
  .home_text h4 {
    max-width: 788px;
    width: 100%;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 50px;
    text-transform: uppercase;
    color: #fddb31;
  }
  .home_text h6 {
    font-weight: 700;
    max-width: 631px;
    width: 100%;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff !important;
    text-transform: capitalize;
  }
  .home_text p {
    max-width: 34ch;
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
  }
  .home_btn {
    margin-top: 15px;
  }
  .home_btn_div {
    margin-left: 0px;
  }
}
@media screen and (min-width: 900px) {
  .home_text h4 {
    max-width: 788px;
    width: 100%;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 70px;
    text-transform: uppercase;
    color: #fddb31;
  }
  .home_text h6 {
    font-weight: 700;
    max-width: 631px;
    width: 100%;
    font-size: 24px;
    line-height: 28px;
    color: #ffffff !important;
  }
  .home_text p {
    max-width: 43ch;
    width: 100%;
    font-weight: 400;
    font-size: 22px;
    line-height: 146.19%;
    color: #ffffff;
  }
  .home_data {
    margin-top: 0%;
  }
}
