.privacy_list_ul {
  list-style-type: none;
  list-style-position: inside;
  padding-left: 0% !important;
}

@media only screen and (min-width: 600px) {
  .privacy_list_ul {
    margin-bottom: 20px;
  }
  .main_heading_privacy {
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;
    color: #3c3c3c;
    margin-top: 0px;
  }
  .main_para {
    font-weight: 400;
    font-size: 18px;
    line-height: 145%;
    color: #616161;
    max-width: 1108px;
    margin-bottom: 1%;
  }
  .main_heading_privacy1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #3c3c3c;

    margin: 30px 0px 20px;
  }
  .privacy_list_ul li {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 145%;
    color: #616161;
    margin-top: 10px;
  }
  .privacy_term_data {
    padding: 2% 6%;
    max-width: 1430px;
    width: 100%;
    margin: 0px auto;
  }
}

@media screen and (max-width: 600px) {
  .privacy_list_ul {
    margin-bottom: 15px;
  }
  .main_heading_privacy {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #3c3c3c;
    margin-bottom: 10px;
  }
  .main_para {
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #616161;
    margin-bottom: 0%;
    max-width: 591px;
  }
  .privacy_term_data {
    padding: 20px 4.67% 40px;
  }
  .main_heading_privacy1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #3c3c3c;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .privacy_list_ul li {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #616161;
    margin-top: 10px;
  }
}
